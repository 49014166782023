<template>
  <div>
    <Nav :show="showNav" @link="linkTo" @menu="scrollTo" :idx="3"></Nav>
    <div class="tab_con">
      <div class="root_con">
        <div
          class="tab_menu"
          :class="{ actived: idx === i }"
          v-for="(item, i) in tabs"
          :key="item.id"
          @click="tabMenu(i, item.id)"
        >
          {{ item.label }}
        </div>
      </div>
    </div>
    <div class="menu_con_box">
      <!-- 公司简介 -->
      <div id="intro" class="menu_con devlopment">
        <div class="menu_title">公司简介</div>
        <div class="line_block"></div>
        <div class="bgImg">
          <div class="intro_title">关于高厚</div>
          <div class="intro_desc">
            杭州高厚智能科技有限公司成立于2011年，是一家服务先行，充分利用物联网、移动互联网、GIS信息技术服务、大数据处理等技术手段的智慧养老科技
            服务企业，通过线上线下互联互通，布局社区居家智慧健康养老服务。
          </div>
          <div class="intro_desc">
            公司一直致力于智慧健康养老综合服务平台开发、运营及社区居家养老服务
            综合体的运营和智慧升级，探索解决居家社区养老难点痛点，在为老服务中不断
            提升自身服务品质及影响力。
          </div>
          <div class="intro_desc">
            2019年被国家三部委评为“国家智慧健康养老示范企业”；已通过信息技术服务标准体系认证（ITSS）；信息安全管理体系认证-ISO27001和质量管理体系认证-ISO9001。拥有5个实用新型专利，1个外观设计专利；13个商标注册；5个软著和3个美术版权等。
          </div>
        </div>
        <img class="backey" :src="require('@/assets/img/backey.png')" alt="" />
      </div>
    </div>
    <div
      id="devlop"
      class="menu_con_box"
      style="position: relative; height: 322px; padding-top: 20px"
    >
      <!-- 发展历程 -->
      <img
        class="devlop-bg"
        :src="require('@/assets/img/dev_history.png')"
        alt=""
      />
      <div class="menu_con devlopment">
        <div class="menu_title" style="color: white">发展历程</div>
        <div class="line_block"></div>
        <div class="coursetime">
          <div
            class="c-tit-item"
            v-for="(item, i) in hisList"
            @click="tapHis(i)"
          >
            <div class="node">
              <div class="txt">{{ item.year }}</div>
              <div
                class="core has_trit"
                :class="{ active: hisIdx === i }"
              ></div>
            </div>
            <div v-if="i !== hisList.length - 1" class="line"></div>
          </div>
        </div>
        <img
          class="dev_history mobile-show"
          :src="require('@/assets/img/dev_history2.png')"
          alt=""
        />
      </div>
    </div>
    <div class="menu_con_box">
      <div class="menu_con">
        <div class="coursetime_desc_ul">
          <div
            class="floatBox"
            :style="{ width: (liWidth + 50) * hisList.length + 'px' }"
          >
            <div
              class="ul_li"
              v-for="(item, i) in hisList"
              :style="{ width: liWidth + 'px' }"
            >
              <div class="li_year">{{ item.year }}</div>
              <div class="li_title">{{ item.title }}</div>
              <div class="li_desc">
                <span class="bule_circle"></span> {{ item.desc }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="menu_con_box">
      <!-- 服务理念 -->
      <div id="idea" class="menu_con devlopment">
        <div class="menu_title">服务理念</div>
        <div class="line_block"></div>
        <div class="idea">
          <img
            class="service_idea_img img1"
            @mouseenter="hover(0, 22)"
            @mouseleave="hover(0, 42)"
            :src="require('@/assets/img/service_idea1.png')"
            alt=""
          />
          <img
            class="service_idea_img img2"
            @mouseenter="hover(1, 86)"
            @mouseleave="hover(1, 106)"
            :src="require('@/assets/img/service_idea2.png')"
            alt=""
          />
          <img
            class="service_idea_img img3"
            @mouseenter="hover(2, 40)"
            @mouseleave="hover(2, 60)"
            :src="require('@/assets/img/service_idea3.png')"
            alt=""
          />
          <img
            class="service_idea_img img4"
            @mouseenter="hover(3, 70)"
            @mouseleave="hover(3, 90)"
            :src="require('@/assets/img/service_idea4.png')"
            alt=""
          />
          <img
            class="service_idea_img img5"
            @mouseenter="hover(4, 64)"
            @mouseleave="hover(4, 84)"
            :src="require('@/assets/img/service_idea5.png')"
            alt=""
          />
          <div>
            <img
              class="service_idea_bot"
              :src="require('@/assets/img/service_idea_bot.png')"
              alt=""
            />
          </div>
        </div>
      </div>
      <!-- 企业文化 -->
      <div id="enterprise" class="menu_con devlopment">
        <div class="menu_title" style="margin-top: 100px">企业文化</div>
        <div class="line_block"></div>
        <div class="culture">
          <div class="culture_item">
            <div><span class="circle"></span>诚信</div>
            <div class="culture_desc">
              立业之本，是一种承诺，更是一种是责任；
            </div>
          </div>
          <div class="culture_item">
            <div><span class="circle"></span>创新</div>
            <div class="culture_desc">
              争创一流、自我超越，工作追求高标准、为客户提供高品质的服务；
            </div>
          </div>
          <div class="culture_item">
            <div><span class="circle"></span>创作</div>
            <div class="culture_desc">团队合作，共同发展，创造价值；</div>
          </div>
          <div class="culture_item">
            <div><span class="circle"></span>分享</div>
            <div class="culture_desc">
              学习新知识、接受新事物，提高效率，创新的工作方式。
            </div>
          </div>
        </div>
        <carousel-3d
          :autoplay="true"
          :autoplayTimeout="2000"
          border="0"
          space="500"
          perspective="0"
          width="495"
          height="327"
        >
          <slide v-for="(item, i) in slides" :index="i" :key="i">
            <img :src="item.src" alt="" />
          </slide>
        </carousel-3d>
      </div>
      <!-- 团队文化 -->
      <div
        id="team"
        class="menu_con devlopment pdbottom"
        style="padding-bottom: 750px"
      >
        <div class="menu_title" style="margin-top: 100px; text-align: left">
          团队文化
        </div>
        <div
          class="line_block"
          style="margin-left: 30px; margin-bottom: 40px"
        ></div>
        <div class="team_cultur">
          <div class="team_desc">
            专业：即企业和员工要走专业道路。保持自身专业特色，从而，发挥自身所具有的特色，在市场中
            立于不败之地。
          </div>
          <div class="team_desc">
            素质：素质是一切业绩的基础。是组织意识、市场意识、和技能养成的总和。
          </div>
          <div class="team_desc">
            灵活：是具有适应性强、协调性好的特点。不仅体现在企业对客户的服务上，也体现员工自身的适
            应性和协调性。
          </div>
          <div class="team_desc">
            力度：企业对客户的执行力和员工自身的执行力。
          </div>
          <img
            class="team_cultur_img pc-show"
            :src="require('@/assets/img/team_culture.png')"
            alt=""
          />
          <img
            class="team_cultur_img mobile-show"
            :src="require('@/assets/img/team_culture2.png')"
            alt=""
          />
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Nav from "@/components/Nav";
import { Carousel3d, Slide } from "vue-carousel-3d";
import Footer from "@/components/Footer";
import $ from "jquery";

export default {
  name: "aboutUs",
  components: {
    Nav,
    Carousel3d,
    Slide,
    Footer,
  },
  data() {
    return {
      showNav: true,
      idx: 0,
      tabs: [
        { id: "intro", label: "公司简介" },
        { id: "devlop", label: "发展历程" },
        { id: "idea", label: "服务理念" },
        { id: "enterprise", label: "企业文化" },
        { id: "team", label: "团队文化" },
      ],
      slides: [
        { src: require("@/assets/img/culture_1.png") },
        { src: require("@/assets/img/culture_2.png") },
        { src: require("@/assets/img/culture_3.png") },
      ],
      hisIdx: 0, // 发展历程 index
      liWidth: 350, // 单个 li 的宽度
      hisList: [
        {
          year: "2011年",
          title: "研发推出物联网定位设备",
          desc: "研发推出物联网定位设备",
        },
        {
          year: "2014年",
          title: "公司成立",
          desc: "杭州高厚智能科技有限公司成立",
        },
        {
          year: "2016年",
          title: "进军养老行业",
          desc: "成为杭州智慧养老综合服务项目的承接单位",
        },
        {
          year: "2018年",
          title: "承接阳光大管家",
          desc: "承接拱墅区阳光大管家服务体系建设",
        },
        {
          year: "2019年",
          title: "汇报工作",
          desc: "向李克强总理汇报助老养老工作",
        },
        {
          year: "2020年",
          title: "承接智慧养老项目",
          desc: "接下城区、拱墅区、江干区、钱塘新区、滨江去五个城区智慧养老服务项目",
        },
      ],
    };
  },
  created() {},
  mounted() {
    window.scrollTo(0, 0);
    window.addEventListener("scroll", this.EventScroll);
  },
  methods: {
    tapHis(i) {
      this.hisIdx = i;
      $(".menu_con_box .floatBox").animate(
        { left: this.liWidth * -i + "px" },
        400
      );
    },
    hover(i, val) {
      $("#idea .idea img")
        .eq(i)
        .animate(
          {
            top: val + "px",
          },
          400
        );
    },
    EventScroll() {
      const scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop; // 滚动条偏移量
      if (scrollTop >= 610) {
        this.showNav = false;
      } else {
        this.showNav = true;
      }
    },
    linkTo(routerName) {
      this.$router.push(routerName);
    },
    scrollTo(id) {
      this.linkTo("aboutUs");
      let idx = this.tabs.findIndex((val) => {
        return val.id == id;
      });
      this.tabMenu(idx, id);
    },
    tabMenu(idx, Id) {
      this.idx = idx;
      window.scrollTo({
        behavior: "smooth",
        top: document.getElementById(Id).offsetTop - 74,
      });
    },
  },
};
</script>
<style lang='less' scoped>
@media screen and (max-width: 767px) {
  // 移动端
  .tab_con {
    display: none;
  }
  .pc-show {
    display: none;
  }
  .menu_con_box {
    width: 100%;
    text-align: center;
    .menu_con {
      margin: auto;
    }
    .bgImg {
      height: 300px;
      text-align: center;
      background-image: url("../../assets/img/about_intro2.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      .intro_title {
        padding-top: 40px;
        padding-bottom: 10px;
        box-sizing: border-box;
        font-size: 20px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
      }
      .intro_desc {
        margin: auto;
        width: 86%;
        letter-spacing: 1px;
        text-align: left;
        line-height: 1.5;
        font-size: 13px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        text-indent: 2em;
        margin-bottom: 10px;
      }
    }
    .backey {
      width: 94%;
      margin: auto;
      margin-top: 10px;
      box-shadow: 0px 0px 14px 0px rgba(211, 211, 211, 0.5);
    }
    .pdbottom {
      padding-bottom: 30px !important;
    }
    .devlopment {
      text-align: center;
      .menu_title {
        margin: 30px 0 20px !important;
        text-align: center !important;
        font-size: 25px;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        font-weight: 500;
        color: #333333;
      }
      .line_block {
        margin: 0 auto 20px !important;
        width: 25px;
        height: 1px;
        background: #2890fc;
      }
      .dev_history {
        width: 90%;
        margin: auto;
      }
      .service_idea_img {
        width: 102px;
        margin: 5px;
      }
      .service_idea_bot {
        width: 100%;
      }
      .culture {
        display: flex;
        flex-wrap: wrap;
        .culture_item {
          width: 50%;
          box-sizing: border-box;
          padding: 5px 10px;
          text-align: center;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
          .culture_desc {
            font-size: 13px;
            padding: 8px 0;
          }
          .circle {
            width: 7px;
            height: 7px;
            margin-right: 6px;
            display: inline-block;
            border-radius: 50%;
            border: 1px solid #196ff9;
          }
        }
      }
      ::v-deep {
        .left-1,
        .right-1 {
          opacity: 0.7 !important;
        }
      }
      .team_cultur {
        padding: 0 10px;
        .team_desc {
          font-size: 13px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
          line-height: 1.5;
          margin-bottom: 20px;
        }
        .team_cultur_img {
          width: 100%;
        }
      }
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  // iPad
}

@media screen and (min-width: 992px) {
  // PC端
  .mobile-show {
    display: none;
  }
  .tab_con {
    width: 100%;
    background: rgba(0, 0, 0, 0.5);
    position: sticky;
    top: 0;
    z-index: 111;
    .root_con {
      width: 1200px;
      height: 50px;
      padding: 0 15px;
      box-sizing: border-box;
      margin: auto;
      margin-top: -50px;
      .actived {
        background: #196ff9;
      }
      .tab_menu {
        width: 160px;
        cursor: pointer;
        height: 100%;
        display: inline-block;
        color: white;
        text-align: center;
        font-size: 18px;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        font-weight: 500;
        line-height: 50px;
      }
    }
  }
  .devlop-bg {
    width: 100%;
    height: 322px;
    position: absolute;
    top: 0;
    z-index: -1;
    left: 0;
    padding-top: 2px;
    color: white;
    // background: url("~@/assets/img/dev_history.png") center 0 no-repeat;
  }
  .menu_con_box {
    width: 100%;
    text-align: center;
    .menu_con {
      width: 1200px;
      margin: auto;
      .coursetime_desc_ul {
        position: relative;
        min-height: 320px;
        overflow: hidden;
        .floatBox {
          position: absolute;
          top: 0;
          left: 0;
          .ul_li {
            float: left;
            text-align: left;
            margin-left: 50px;
            .li_year {
              padding-top: 50px;
              padding-bottom: 10px;
              font-size: 20px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #000015;
              border-bottom: 1px solid #cdcdcd;
            }
            .li_title {
              padding-left: 10px;
              margin-top: 14px;
              font-size: 18px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #333333;
            }
            .li_desc {
              padding-left: 10px;
              margin-top: 12px;
              font-size: 16px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #878787;
              .bule_circle {
                width: 5px;
                height: 5px;
                vertical-align: middle;
                display: inline-block;
                background: #2890fc;
                border-radius: 50%;
              }
            }
          }
        }
      }
    }
    .bgImg {
      height: 598px;
      text-align: center;
      margin-top: 50px;
      background-image: url("../../assets/img/about_intro.jpg");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      .intro_title {
        padding-top: 42px;
        padding-bottom: 13px;
        box-sizing: border-box;
        font-size: 36px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
      }
      .intro_desc {
        margin: auto;
        width: 973px;
        letter-spacing: 1px;
        text-align: left;
        line-height: 1.5;
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        text-indent: 2em;
        margin-bottom: 10px;
      }
    }
    .backey {
      width: 1200px;
      height: 457px;
      margin-top: 66px;
      margin-bottom: 60px;
      box-shadow: 0px 0px 28px 0px rgba(211, 211, 211, 0.5);
    }
    .devlopment {
      text-align: center;
      .menu_title {
        margin: 50px 0 30px;
        text-align: center;
        font-size: 30px;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        font-weight: 500;
        color: #333333;
      }
      .line_block {
        margin: 0 auto 50px;
        width: 50px;
        height: 2px;
        background: #2890fc;
      }
      .coursetime {
        width: 800px;
        margin: 0 auto;
        padding-top: 40px;
        color: #fff;
        box-sizing: border-box;
        .c-tit-item {
          float: left;
          width: 153px;
          .node {
            width: 26px;
            height: 26px;
            float: left;
            position: relative;
            border-radius: 50%;
            background: hsla(0, 0%, 100%, 0.28);
            box-shadow: 0 4px 20px 0 #0056d6;
            cursor: pointer;
            .txt {
              position: absolute;
              font-size: 16px;
              top: -30px;
              width: 200px;
              text-align: center;
              left: 50%;
              margin-left: -100px;
            }
            .core {
              width: 16px;
              height: 16px;
              position: absolute;
              top: 5px;
              left: 5px;
              border-radius: 50%;
              background-color: #fff;
            }
            .active {
              background: #ff9000;
            }
            .has_trit {
              -webkit-transition: all 0.3s ease-in-out;
              transition: all 0.3s ease-in-out;
            }
          }
          .line {
            float: left;
            height: 2px;
            background: hsla(0, 0%, 100%, 0.5);
            width: 127px;
            margin-top: 11px;
            position: relative;
          }
          &:last-child {
            width: 26px;
          }
        }
      }
      .service_idea_img {
        width: 192px;
        position: relative;
      }
      .img1 {
        top: 42px;
        right: 58px;
      }
      .img2 {
        right: 24px;
        top: 106px;
      }
      .img3 {
        top: 60px;
        left: 16px;
      }
      .img4 {
        top: 90px;
        left: 58px;
      }
      .img5 {
        top: 84px;
        left: 100px;
      }
      .service_idea_bot {
        width: 1200px;
        height: 171px;
        position: relative;
        z-index: -1;
      }
      .culture {
        display: flex;
        .culture_item {
          width: 25%;
          box-sizing: border-box;
          padding: 0 10px;
          text-align: center;
          font-size: 20px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
          .culture_desc {
            font-size: 18px;
            margin-top: 10px;
          }
        }
      }
      ::v-deep {
        .left-1,
        .right-1 {
          opacity: 0.7 !important;
        }
      }
      .team_cultur {
        text-align: left;
        position: relative;
        .team_desc {
          font-size: 18px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
          line-height: 1.5;
          margin-bottom: 20px;
        }
        .team_cultur_img {
          position: absolute;
          left: 145px;
          top: 128px;
          width: 1000px;
        }
      }
    }
  }
}
</style>